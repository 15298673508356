import React, { useState } from 'react'
import './WisePriceFunction.css'
import '../../utils/styles/styles.css'
import mixpanel from 'mixpanel-browser'
import ActionButton from '../../components/ActionButton/ActionButton'
import copy from 'copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { useAuthStore, useErrorStore, useLoadingStore } from '../../state/store'

export default function WisePriceFunction() {
    const { currentUser } = useAuthStore((state) => state)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const [tooltipText, setTooltipText] = useState('Click to copy')

    const generateWisepriceFunctionTutorial = async () => {
        setLoading({ isLoading: true, message: 'Generating tutorial...' })
        mixpanel.track('WISEPRICE_TUTORIAL_GENERATED', {
            distinct_id: currentUser.email,
            isSubscribed: currentUser.isSubscribed,
            subscriptionType: currentUser.subscriptionType,
        })
        try {
            await Excel.run(async (context) => {
                // create the new sheet
                var worksheets = context.workbook.worksheets
                var newSheet = worksheets.add('WISEPRICE Function Tutorial')

                newSheet.activate()

                var tutorialHeaders = [
                    [
                        'Company',
                        'Name',
                        'Price',
                        'PE',
                        'Volume',
                        'Price avg 50',
                    ],
                ]
                var tutorialValues = [
                    ['AAPL', '=WISEPRICE(A2:A4,B1:F1)', '', '', '', ''],
                    ['AMZN', '', '', '', '', ''],
                    ['TSLA', '', '', '', '', ''],
                ]
                var tutorialStatements = [
                    ['Cell B2 is pre-populated with our WISEPRICE function.'],
                    [
                        'In this function we use multiple tickers parameters to get the data for all stocks at once.',
                    ],
                    [
                        'You can refresh this data any time by pressing the refresh button.',
                    ],
                    [''],
                    [''],
                    [
                        'The WISEPRICE fuction also offers other data like historical prices.',
                    ],
                    ['Change the ticker in cell B14 to see this data.'],
                ]

                var tutorialPart2 = [
                    ['Company', 'AAPL'],
                    ['', ''],
                    ['Start date', '2022-01-01'],
                    ['End date', '2023-01-01'],
                    ['', ''],
                    ['=WISEPRICE(B14, "open", ,B16,B17)', ''],
                ]
                var headerRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(
                        tutorialHeaders.length - 1,
                        tutorialHeaders[0].length - 1
                    )
                headerRange.values = tutorialHeaders
                headerRange.format.columnWidth = 125
                headerRange.format.font.color = '#F7F8F8'
                headerRange.format.fill.color = '#364165'

                var tutorialRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A2')
                    .getResizedRange(
                        tutorialValues.length - 1,
                        tutorialValues[0].length - 1
                    )
                tutorialRange.values = tutorialValues
                tutorialRange.numberFormat = [
                    [
                        '',
                        '',
                        '$#,###.00;($#,###.00)',
                        '0.00;(0.00)',
                        '#,##0',
                        '$#,###.00;($#,###.00)',
                    ],
                    [
                        '',
                        '',
                        '$#,###.00;($#,###.00)',
                        '0.00;(0.00)',
                        '#,##0',
                        '$#,###.00;($#,###.00)',
                    ],
                    [
                        '',
                        '',
                        '$#,###.00;($#,###.00)',
                        '0.00;(0.00)',
                        '#,##0',
                        '$#,###.00;($#,###.00)',
                    ],
                ]

                var notesRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A6')
                    .getResizedRange(
                        tutorialStatements.length - 1,
                        tutorialStatements[0].length - 1
                    )
                notesRange.values = tutorialStatements
                notesRange.format.font.bold = true

                var tutorialPart2Range = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A14')
                    .getResizedRange(
                        tutorialPart2.length - 1,
                        tutorialPart2[0].length - 1
                    )
                tutorialPart2Range.values = tutorialPart2

                var companyRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('B14')
                    .getResizedRange(0, 0)
                companyRange.format.font.bold = true

                var dateRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A16')
                    .getResizedRange(1, 0)
                dateRange.format.font.color = '#F7F8F8'
                dateRange.format.fill.color = '#364165'
                await context.sync()
            })
        } catch (error) {
            setError({
                hasError: true,
                errorMessage:
                    "Something went wrong. Please make sure the sheet doesn't exist already.",
            })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    return (
        <div>
            <div>
                <span className="wise-green">Symbol(s)</span>
                <div className="param-example">
                    mandatory ex. "AAPL", A2:A12
                </div>
            </div>
            <div>
                <span className="wise-green">Parameter(s)</span>
                <div className="param-example">
                    mandatory ex. "Price", A1:A10
                </div>
            </div>
            <div>
                <span className="wise-green">Number of days to end</span>
                <div className="param-example">optional ex. 20</div>
            </div>
            <div>
                <span className="wise-green">Start date</span>
                <div className="param-example">optional ex. "01/01/2022"</div>
            </div>
            <div>
                <span className="wise-green">End date</span>
                <div className="param-example">optional ex. "01/30/2022"</div>
            </div>
            <br />
            <br />
            <div
                className="example"
                onClick={() => {
                    copy('=WISEPRICE("TSLA","Price")', {
                        format: 'text/plain',
                    })
                    setTooltipText('Copied!')
                }}
                data-tip=""
            >
                =WISEPRICE("<span>TSLA</span>","<span>Price</span>")
                <ReactTooltip
                    effect="solid"
                    place="top"
                    afterHide={() => setTooltipText('Click to copy')}
                    getContent={() => tooltipText}
                />
            </div>
            <div
                className="example"
                onClick={() => {
                    copy('=WISEPRICE("AAPL","Close", 2)', {
                        format: 'text/plain',
                    })
                    setTooltipText('Copied!')
                }}
                data-tip=""
            >
                =WISEPRICE("<span>AAPL</span>","<span>Close</span>",
                <span>2</span>)
                <ReactTooltip
                    effect="solid"
                    place="top"
                    afterHide={() => setTooltipText('Click to copy')}
                    getContent={() => tooltipText}
                />
            </div>
            <div className="tutorial-button-container">
                <ActionButton
                    title={'Click here for a tutorial'}
                    action={generateWisepriceFunctionTutorial}
                    type={'button'}
                    color={'#1FB723'}
                />
            </div>
            {currentUser.isSubscribed ? null : (
                <>
                    <div className="full-version-statement">
                        Trial Mode: Only AMZN, TSLA, AAPL are available
                    </div>
                    <div className="full-version-container">
                        <a href="https://wisesheets.io/payment" target="_blank">
                            <button id="fullversion">Get Full Version</button>
                        </a>
                    </div>
                </>
            )}
            <div className="link-footer">
                <div className="click-here">
                    <a
                        className="link"
                        target="_blank"
                        href="https://wisesheets.io/available-data"
                    >
                        Available data
                    </a>
                </div>
                <div className="click-here">
                    <a
                        className="link"
                        target="_blank"
                        href="https://wisesheets.io/pages/docs"
                    >
                        Wisesheets docs
                    </a>
                </div>
            </div>
        </div>
    )
}
