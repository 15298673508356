import React, { useEffect, useRef, useState } from 'react'
import '../../utils/styles/styles.css'
import styles from './Screener.module.css'
import mixpanel from 'mixpanel-browser'
import ActionButton from '../../components/ActionButton/ActionButton'
import axios from 'axios'
import {
    useAuthStore,
    useErrorStore,
    useLoadingStore,
    useBucketStore,
} from '../../state/store'
import { useFieldArray, useForm } from 'react-hook-form'
import { RiCloseLine } from 'react-icons/ri'
import { API_URL_V3 } from '../StatementDump/constants'
import {
    SCREENER_INPUT_MAP,
    SCREENER_FILTER_KEYS,
    DEFAULT_SELECTED_FILTER_OPTION_STATE,
    getValidIndustries,
} from './utils'
import { RxGrid } from 'react-icons/rx'
import ReactTooltip from 'react-tooltip'
import { SCREENER_API_KEY } from './constants'
import { httpClient } from '../../utils/http'
import BucketListItem from '../../components/BucketListItem/BucketListItem'

enum ARITHMETIC_FILTER_OPTIONS {
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
}

type Field = {
    id: string
    name: string
}

const Screener = () => {
    const { currentUser } = useAuthStore((state) => state)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const {
        buckets,
        saveBucket,
        removeBucket,
        setBucketName,
        setBucketTickersRange,
        setBucketParametersRange,
    } = useBucketStore((state) => state)
    const [isSelectFiltersModalOpen, setIsSelectFiltersModalOpen] =
        useState(false)

    // **** state for multi select values **** //
    const [currentMultiSelectFilter, setCurrentMultiSelectFilter] = useState('')
    // maintains a list of all the selected values for each multi select filter
    const [
        selectedMultiValueFilterOptions,
        setSelectedMultiValueFilterOptions,
    ] = useState<Record<string, string[]>>(DEFAULT_SELECTED_FILTER_OPTION_STATE)

    const [selectedMiltiValueFilterData, setSelectedMultiValueFilterData] =
        useState([])
    const isPremiumUser = currentUser.subscriptionType === 'PREMIUM'
    // *************************************** //

    const [shouldShowSearch, setShouldShowSearch] = useState(false)
    const [searchInput, setSearchInput] = useState('')

    const {
        register,
        unregister,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    // TODO: figure out how to type fields
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'screener-form', // unique name for your Field Array
    })

    useEffect(() => {
        remove()
        append([
            { name: SCREENER_FILTER_KEYS.MARKET_CAP },
            { name: SCREENER_FILTER_KEYS.PRICE },
            { name: SCREENER_FILTER_KEYS.SECTOR },
            { name: SCREENER_FILTER_KEYS.INDUSTRY },
            { name: SCREENER_FILTER_KEYS.EXCHANGE },
            { name: SCREENER_FILTER_KEYS.BETA },
            { name: SCREENER_FILTER_KEYS.VOLUME },
            { name: SCREENER_FILTER_KEYS.DIVIDEND },
            { name: SCREENER_FILTER_KEYS.IS_ETF },
            { name: SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING },
            { name: SCREENER_FILTER_KEYS.COUNTRY },
        ])
    }, [])

    const runScreener = async (data: any) => {
        if (!currentUser.isSubscribed) {
            setError({
                hasError: true,
                errorMessage: 'Please subscribe to screen stocks.',
            })
            return
        }
        if (!isPremiumUser) {
            setError({
                hasError: true,
                errorMessage: 'Please upgrade to screen stocks.',
            })
            return
        }
        setLoading({ isLoading: true, message: 'Screening data...' })
        let params = {
            limit: 5000,
            apikey: SCREENER_API_KEY,
            marketCapMoreThan:
                data[SCREENER_FILTER_KEYS.MARKET_CAP]?.option ===
                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                    ? data[SCREENER_FILTER_KEYS.MARKET_CAP]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            marketCapLowerThan:
                data[SCREENER_FILTER_KEYS.MARKET_CAP]?.option ===
                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                    ? data[SCREENER_FILTER_KEYS.MARKET_CAP]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            priceMoreThan:
                data[SCREENER_FILTER_KEYS.PRICE]?.option ===
                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                    ? data[SCREENER_FILTER_KEYS.PRICE]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            priceLowerThan:
                data[SCREENER_FILTER_KEYS.PRICE]?.option ===
                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                    ? data[SCREENER_FILTER_KEYS.PRICE]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            betaMoreThan:
                data[SCREENER_FILTER_KEYS.BETA]?.option ===
                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                    ? data[SCREENER_FILTER_KEYS.BETA]?.value.replaceAll(',', '')
                    : undefined,
            betaLowerThan:
                data[SCREENER_FILTER_KEYS.BETA]?.option ===
                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                    ? data[SCREENER_FILTER_KEYS.BETA]?.value.replaceAll(',', '')
                    : undefined,
            volumeMoreThan:
                data[SCREENER_FILTER_KEYS.VOLUME]?.option ===
                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                    ? data[SCREENER_FILTER_KEYS.VOLUME]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            volumeLowerThan:
                data[SCREENER_FILTER_KEYS.VOLUME]?.option ===
                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                    ? data[SCREENER_FILTER_KEYS.VOLUME]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            dividendMoreThan:
                data[SCREENER_FILTER_KEYS.DIVIDEND]?.option ===
                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                    ? data[SCREENER_FILTER_KEYS.DIVIDEND]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            dividendLowerThan:
                data[SCREENER_FILTER_KEYS.DIVIDEND]?.option ===
                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                    ? data[SCREENER_FILTER_KEYS.DIVIDEND]?.value.replaceAll(
                          ',',
                          ''
                      )
                    : undefined,
            isActivelyTrading:
                data[SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING]?.value,
            isEtf: data[SCREENER_FILTER_KEYS.IS_ETF]?.value,
            sector:
                data[SCREENER_FILTER_KEYS.SECTOR] &&
                data[SCREENER_FILTER_KEYS.SECTOR]?.option
                    ? // using values here to rectify an issue with commas in the name of the selected field
                      Object.values(
                          data[SCREENER_FILTER_KEYS.SECTOR]?.option
                      ).join(',')
                    : undefined,
            industry:
                data[SCREENER_FILTER_KEYS.INDUSTRY] &&
                data[SCREENER_FILTER_KEYS.INDUSTRY]?.option
                    ? // using values here to rectify an issue with commas in the name of the selected field
                      Object.values(
                          data[SCREENER_FILTER_KEYS.INDUSTRY]?.option
                      ).join(',')
                    : undefined,
            country:
                data[SCREENER_FILTER_KEYS.COUNTRY] &&
                data[SCREENER_FILTER_KEYS.COUNTRY]?.option
                    ? // using values here to rectify an issue with commas in the name of the selected field
                      Object.values(
                          data[SCREENER_FILTER_KEYS.COUNTRY]?.option
                      ).join(',')
                    : undefined,
            exchange:
                data[SCREENER_FILTER_KEYS.EXCHANGE]?.option &&
                data[SCREENER_FILTER_KEYS.EXCHANGE]?.option
                    ? // using values here to rectify an issue with commas in the name of the selected field
                      Object.values(
                          data[SCREENER_FILTER_KEYS.EXCHANGE]?.option
                      ).join(',')
                    : null,
        }
        var clonedParams = JSON.parse(JSON.stringify(params))
        delete clonedParams.apikey
        delete clonedParams.limit
        mixpanel.track('RUN_SCREENER', {
            distinct_id: currentUser.email,
            isSubscribed: currentUser.isSubscribed,
            subscriptionType: currentUser.subscriptionType,
            screener_params: clonedParams,
        })
        try {
            const response: { data: any } = await axios.get(
                API_URL_V3 + '/stock-screener',
                {
                    params,
                }
            )
            if (response.data.length === 0) {
                setError({ hasError: true, errorMessage: 'No data found.' })
                return
            }
            const headings = Object.keys(response.data[0])
            const structuredData = response.data.map(Object.values)
            structuredData.unshift(headings)
            await Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets
                const sheetName =
                    'Screened data ' +
                    new Date().getHours() +
                    ' ' +
                    new Date().getMinutes() +
                    ' ' +
                    new Date().getSeconds()
                const newSheet = worksheets.add(sheetName)
                newSheet.activate()
                // put the data in the sheet
                var dataRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(
                        structuredData.length - 1,
                        structuredData[0].length - 1
                    )
                dataRange.values = structuredData
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                // put the font colour and background colour on the headings
                var hearderRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(0, headings.length - 1)
                hearderRange.format.font.color = '#F7F8F8'
                hearderRange.format.fill.color = '#364165'
                newSheet.autoFilter.apply(dataRange)
            })
        } catch (e) {
            console.log(e)
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const handleNewFilterCheckboxChange = (e) => {
        if (!e.target.checked) {
            fields.map((field: Field, index) => {
                if (field.name === e.target.value) {
                    remove(index)
                }
            })
        } else append({ name: e.target.value })
    }

    const renderFormOptions = () => {
        return Object.keys(SCREENER_INPUT_MAP).map((key, index) => (
            <div key={index} className={styles.filterOption}>
                <input
                    checked={fields.some((field: Field) => field.name === key)}
                    value={key}
                    type="checkbox"
                    onChange={handleNewFilterCheckboxChange}
                    id={`${key}checkbox`}
                />
                <label style={{ color: '#000000' }} htmlFor={`${key}checkbox`}>
                    {SCREENER_INPUT_MAP[key].displayableName}
                </label>
            </div>
        ))
    }

    const openModalWithMultiSelectOptions = (multiSelectFieldType: string) => {
        setCurrentMultiSelectFilter(multiSelectFieldType)
        if (multiSelectFieldType === SCREENER_FILTER_KEYS.INDUSTRY) {
            const allowedIndustries = getValidIndustries(
                selectedMultiValueFilterOptions[SCREENER_FILTER_KEYS.SECTOR]
            )
            setSelectedMultiValueFilterData(allowedIndustries)
        } else {
            setSelectedMultiValueFilterData(
                SCREENER_INPUT_MAP[multiSelectFieldType].multiSelectData
            )
        }
        if (
            typeof SCREENER_INPUT_MAP[multiSelectFieldType]
                .multiSelectData[0] === 'object' ||
            typeof SCREENER_INPUT_MAP[multiSelectFieldType].multiSelectData ===
                'function'
        ) {
            setShouldShowSearch(true)
        }
    }

    const handleMultiSelectValueCheckboxChange = (e) => {
        const value = e.target.value
        const isChecked = e.target.checked
        // need to unregister all selected values one change so when it re-renders it registers the correct options
        unregister(`${currentMultiSelectFilter}.option`)

        setSelectedMultiValueFilterOptions((previous) => {
            const newState = { ...previous }

            if (isChecked) {
                newState[currentMultiSelectFilter].push(value)
            } else {
                newState[currentMultiSelectFilter] = newState[
                    currentMultiSelectFilter
                ].filter((item) => item !== value)
            }
            return newState
        })
    }

    const shouldMultiFilterOptionBeChecked = (value: string): boolean => {
        return selectedMultiValueFilterOptions[currentMultiSelectFilter].some(
            (field) => field === value
        )
    }

    const handleMultiSelectOptionsModalClose = () => {
        setCurrentMultiSelectFilter('')
        setSearchInput('')
        setShouldShowSearch(false)
    }

    const renderValidationInputs = () => {
        return fields.map((field: Field, index) => {
            if (SCREENER_INPUT_MAP[field.name].type === 'arithmeticInput') {
                return (
                    <div className={styles.filterItem} key={field.name}>
                        <div className={styles.filterContainer}>
                            <div className={styles.inputContainer}>
                                <div className={styles.leftInputContainer}>
                                    <span className={styles.filterTitle}>
                                        {
                                            SCREENER_INPUT_MAP[field.name]
                                                .displayableName
                                        }
                                    </span>
                                    <select
                                        name="arithmeticFilter"
                                        className={styles.arithmeticFilter}
                                        {...register(`${field.name}.option`)}
                                    >
                                        <option
                                            value={
                                                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                                            }
                                        >
                                            greater than
                                        </option>
                                        <option
                                            value={
                                                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                                            }
                                        >
                                            less than
                                        </option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    className={styles.arithmeticInput}
                                    id={`${field.name}.value`}
                                    {...register(`${field.name}.value`, {
                                        onChange: (e) => {
                                            if (
                                                field.name ===
                                                    SCREENER_FILTER_KEYS.BETA ||
                                                field.name ===
                                                    SCREENER_FILTER_KEYS.DIVIDEND
                                            ) {
                                                const numWithoutLetters =
                                                    e.target.value.replaceAll(
                                                        /[a-zA-Z]/g,
                                                        ''
                                                    )
                                                setValue(
                                                    `${field.name}.value`,
                                                    numWithoutLetters
                                                )
                                            } else {
                                                const numWithoutLetters =
                                                    e.target.value.replaceAll(
                                                        /[a-zA-Z]/g,
                                                        ''
                                                    )
                                                const numWithoutCommas =
                                                    numWithoutLetters.replaceAll(
                                                        ',',
                                                        ''
                                                    )
                                                const formattedNumber =
                                                    Number(
                                                        numWithoutCommas
                                                    ).toLocaleString('en-US')
                                                setValue(
                                                    `${field.name}.value`,
                                                    formattedNumber
                                                )
                                            }
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className={styles.deleteButton}>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={'white'}
                                size={14}
                            />
                        </div>
                    </div>
                )
            } else if (SCREENER_INPUT_MAP[field.name].type === 'booleanInput') {
                return (
                    <div className={styles.filterItem} key={field.name}>
                        <div className={styles.filterContainer}>
                            <div className={styles.inputContainer}>
                                <span className={styles.filterTitle}>
                                    {
                                        SCREENER_INPUT_MAP[field.name]
                                            .displayableName
                                    }
                                </span>
                            </div>
                            <input
                                type={'checkbox'}
                                {...register(`${field.name}.value`)}
                            />
                        </div>
                        <div className={styles.deleteButton}>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={'white'}
                                size={14}
                            />
                        </div>
                    </div>
                )
            } else if (
                SCREENER_INPUT_MAP[field.name].type === 'multiValueInput'
            ) {
                return (
                    <div className={styles.filterItem} key={field.name}>
                        <div className={styles.filterContainer}>
                            <div
                                className={styles.inputContainer}
                                style={{ flexWrap: 'wrap' }}
                            >
                                <input
                                    type={'hidden'}
                                    {...register(`${field.name}.value`)}
                                    value={field.name}
                                />
                                <span className={styles.filterTitle}>
                                    {
                                        SCREENER_INPUT_MAP[field.name]
                                            .displayableName
                                    }
                                </span>
                                {selectedMultiValueFilterOptions[field.name]
                                    .length
                                    ? selectedMultiValueFilterOptions[
                                          field.name
                                      ].map((selectedFilterOption, index) => (
                                          <div
                                              key={selectedFilterOption}
                                              className={
                                                  styles.selectedFilterOption
                                              }
                                          >
                                              <RiCloseLine
                                                  onClick={() => {
                                                      setSelectedMultiValueFilterOptions(
                                                          (previous) => {
                                                              const newState = {
                                                                  ...previous,
                                                              }

                                                              newState[
                                                                  field.name
                                                              ] = newState[
                                                                  field.name
                                                              ].filter(
                                                                  (item) =>
                                                                      item !==
                                                                      selectedFilterOption
                                                              )

                                                              return newState
                                                          }
                                                      )
                                                  }}
                                                  style={{
                                                      marginRight: 2,
                                                      cursor: 'pointer',
                                                      paddingTop: 1,
                                                  }}
                                                  color={'black'}
                                                  size={12}
                                              />
                                              {selectedFilterOption}
                                              <input
                                                  type="hidden"
                                                  {...register(
                                                      `${field.name}.option.${index}`
                                                  )}
                                                  value={selectedFilterOption}
                                              />
                                          </div>
                                      ))
                                    : null}
                                <div
                                    className={styles.addFilterValueButton}
                                    onClick={() => {
                                        openModalWithMultiSelectOptions(
                                            field.name
                                        )
                                    }}
                                >
                                    + {SCREENER_INPUT_MAP[field.name].ctaText}
                                </div>
                            </div>
                        </div>
                        <div className={styles.deleteButton}>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={'white'}
                                size={14}
                            />
                        </div>
                    </div>
                )
            } else {
                return null
            }
        })
    }

    const handleChange = (e) => {
        e.preventDefault()
        setSearchInput(e.target.value)
    }

    useEffect(() => {
        if (!currentMultiSelectFilter) return
        const arrayToSearch =
            SCREENER_INPUT_MAP[currentMultiSelectFilter].multiSelectData
        const dataToBeFiltered =
            typeof arrayToSearch === 'function'
                ? arrayToSearch()
                : arrayToSearch

        if (searchInput.length > 0) {
            setSelectedMultiValueFilterData(
                dataToBeFiltered.filter((data) => {
                    if (typeof arrayToSearch === 'function') {
                        return data
                            .toUpperCase()
                            .match(searchInput.toUpperCase())
                    } else {
                        return data.name
                            .toUpperCase()
                            .match(searchInput.toUpperCase())
                    }
                })
            )
        } else {
            setSelectedMultiValueFilterData(dataToBeFiltered)
        }
    }, [searchInput])

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
    } = useForm()

    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)

    const tabsRef = useRef([])

    const tabs = [{ label: 'Get List' }, { label: 'Get Data' }]

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex]
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0)
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
        }

        setTabPosition()
        window.addEventListener('resize', setTabPosition)

        return () => window.removeEventListener('resize', setTabPosition)
    }, [activeTabIndex])

    const letterToColumn = (letter: string) => {
        var column = 0,
            length = letter.length
        for (var i = 0; i < length; i++) {
            column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1)
        }
        return column
    }

    const runScreenerData = async (
        data: any,
        shouldSave: boolean,
        event: 'GET_SCREENER_DATA' | 'REFRESH_BUCKET'
    ): Promise<void> => {
        if (!currentUser.isSubscribed) {
            setError({
                hasError: true,
                errorMessage: 'Please subscribe to screen stocks.',
            })
            return
        }
        setLoading({ isLoading: true, message: 'Fetching data...' })
        try {
            await Excel.run(async (context) => {
                let sheet = context.workbook.worksheets.getActiveWorksheet()
                const tickerRangeValues = sheet.getRange(data.tickerRange)
                const paramRangeValues = sheet.getRange(data.parameterRange)
                tickerRangeValues.load('values')
                paramRangeValues.load('values')
                await context.sync()
                mixpanel.track(event, {
                    distinct_id: currentUser.email,
                    isSubscribed: currentUser.isSubscribed,
                    subscriptionType: currentUser.subscriptionType,
                    ticker_count: tickerRangeValues.values.flat().length,
                    parameters: paramRangeValues.values.flat(),
                })
                if (tickerRangeValues.values.flat().length > 1000) {
                    setLoading({ isLoading: false, message: '' })

                    setError({
                        hasError: true,
                        errorMessage:
                            'Please select less than 1,000 tickers to get data.',
                    })
                    return
                }

                const resp = await httpClient.post(
                    '/api/private/screener/data',
                    {
                        tickers: tickerRangeValues.values.flat(),
                        parameters: paramRangeValues.values.flat(),
                    }
                )

                const column = letterToColumn(
                    data.parameterRange
                        .toUpperCase()
                        .split(':')[0]
                        .split(/[0-9]/)[0]
                )
                const row = Number(
                    data.tickerRange
                        .toUpperCase()
                        .split(':')[0]
                        .split(/[a-zA-Z]/)[1]
                )
                if (shouldSave) {
                    saveBucket({
                        tickerRange: data.tickerRange,
                        parameterRange: data.parameterRange,
                        name: 'Bucket ' + (buckets.length + 1),
                    })
                }

                var firstCell = sheet.getCell(row - 1, column - 1)
                var lastCell = sheet.getCell(
                    row + resp.data.length - 2,
                    column + resp.data[0].length - 2
                )
                var dataRange = firstCell.getBoundingRect(lastCell)
                dataRange.values = resp.data
                await context.sync()
            })
        } catch (e) {
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    useEffect(() => {
        setValue2('tickerRange', 'A1:A1')
        setValue2('parameterRange', 'B1:B1')
    }, [])

    const runScreenerDemo = async () => {
        setLoading({ isLoading: true, message: 'Running screener...' })
        mixpanel.track('RUN_SCREENER_DEMO', {
            distinct_id: currentUser.email,
            isSubscribed: currentUser.isSubscribed,
            subscriptionType: currentUser.subscriptionType,
        })
        try {
            await Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets
                const sheetName =
                    'Screened data ' +
                    new Date().getHours() +
                    ' ' +
                    new Date().getMinutes() +
                    ' ' +
                    new Date().getSeconds()
                const newSheet = worksheets.add(sheetName)
                newSheet.activate()
                let sheet = context.workbook.worksheets.getActiveWorksheet()
                const tickers = [
                    'TSM',
                    'NVDA',
                    'T.TO',
                    'RELIANCE.NS',
                    'BHP.AX',
                    'HSBC.L',
                    'BNP.PA',
                    'AMD',
                    'AMAT',
                    'ADI',
                    'LRCX',
                    'MU',
                    'KLAC',
                    'APH',
                    'IFNNY',
                    'NXPI',
                    'MCHP',
                    'TEL',
                    'STM',
                    'MRVL',
                    'MRAAY',
                    'GFS',
                    'KEYS',
                    'ON',
                    'HXGBY',
                    'GLW',
                    'FTV',
                    'TDY',
                    'GRMN',
                    'UMC',
                ]
                const parameters = [
                    'Revenue',
                    'Total Stockholders Equity',
                    'Free Cash Flow',
                    'ROE',
                    'Revenue Growth',
                ]

                const resp = await httpClient.post(
                    '/api/private/screener/data',
                    {
                        tickers: tickers,
                        parameters: parameters,
                    }
                )

                let dataToWriteToSheet = resp.data

                for (let i = 0; i < dataToWriteToSheet.length; i++) {
                    dataToWriteToSheet[i].unshift(tickers[i])
                }

                parameters.unshift('Stock')

                dataToWriteToSheet.unshift(parameters)
                var firstCell = sheet.getCell(0, 0)
                var lastCell = sheet.getCell(
                    dataToWriteToSheet.length - 1,
                    dataToWriteToSheet[0].length - 1
                )
                var dataRange = firstCell.getBoundingRect(lastCell)
                dataRange.values = dataToWriteToSheet

                var firstCell1 = sheet.getCell(0, 0)
                var lastCell1 = sheet.getCell(
                    0,
                    dataToWriteToSheet[0].length - 1
                )
                var range1 = firstCell1.getBoundingRect(lastCell1)
                range1.format.fill.color = '#364165'
                range1.format.font.color = '#F7F8F8'

                var firstCell2 = sheet.getCell(1, 1)
                var lastCell2 = sheet.getCell(29, 3)
                var range2 = firstCell2.getBoundingRect(lastCell2)
                range2.numberFormat = [['#,##0.00_);(#,##0.00)']]

                var firstCell3 = sheet.getCell(1, 4)
                var lastCell3 = sheet.getCell(29, 5)
                var range3 = firstCell3.getBoundingRect(lastCell3)
                range3.numberFormat = [['0.00%']]

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                await context.sync()
            })
        } catch (e) {
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <div>
            <div className="page-description">
                {activeTabIndex === 0
                    ? 'Get a list of securities based on specific criteria'
                    : 'Get the data to compare hundreds of securities in one view'}
            </div>
            <div className={styles.tabsContainer}>
                <div className={styles.tabs}>
                    {tabs.map((tab, index) => {
                        return (
                            <div
                                className={styles.tab}
                                key={index}
                                ref={(el) => (tabsRef.current[index] = el)}
                                onClick={() => setActiveTabIndex(index)}
                            >
                                {tab.label}
                            </div>
                        )
                    })}
                    <span
                        className={styles.tabHighlight}
                        style={{
                            left: tabUnderlineLeft,
                            width: tabUnderlineWidth,
                        }}
                    />
                </div>
            </div>
            {!isPremiumUser && activeTabIndex === 0 ? (
                <div
                    className="click-here"
                    style={{ alignSelf: 'center', marginBottom: 20 }}
                >
                    <a
                        className="link"
                        target="_blank"
                        href={
                            currentUser.isSubscribed
                                ? 'https://www.wisesheets.io/manage-account'
                                : 'https://www.wisesheets.io/payment'
                        }
                    >
                        Upgrade to elite plan for access
                    </a>
                </div>
            ) : null}
            <div>
                {activeTabIndex === 0 ? (
                    <form
                        onSubmit={handleSubmit((data) => runScreener(data))}
                        style={{
                            opacity: isPremiumUser ? 1 : 0.4,
                            pointerEvents: isPremiumUser ? 'all' : 'none',
                        }}
                    >
                        {renderValidationInputs()}
                        <div
                            onClick={() => setIsSelectFiltersModalOpen(true)}
                            className={styles.addAnotherFilter}
                        >
                            + Add another filter
                        </div>
                        {Object.keys(errors).length > 0 ? (
                            <div className={styles.errorMessage}>
                                Please make sure all fields are filled or remove
                                empty fields
                            </div>
                        ) : null}
                        <ActionButton title="Get data" type="submit" />
                    </form>
                ) : (
                    <form
                        onSubmit={handleSubmit2((data) =>
                            runScreenerData(data, true, 'GET_SCREENER_DATA')
                        )}
                        onKeyDown={(e) => checkKeyDown(e)}
                    >
                        <label
                            htmlFor="tickerRange"
                            className={styles.rangeLabel}
                        >
                            Ticker Range
                        </label>
                        <div className={styles.customInput}>
                            <input
                                id="tickerRange"
                                type="text"
                                placeholder="Ticker range"
                                {...register2('tickerRange', {
                                    required: true,
                                })}
                            />
                            <div className={styles.rxgridContainer}>
                                <RxGrid
                                    data-for="select-ticker-range"
                                    data-tip="Highlight a range then click me"
                                    className={styles.rxgrid}
                                    onClick={async () => {
                                        await Excel.run(async (context) => {
                                            setLoading({
                                                isLoading: true,
                                                message: '',
                                            })
                                            let range =
                                                context.workbook.getSelectedRange()
                                            range.load('address')

                                            await context.sync()

                                            setValue2(
                                                'tickerRange',
                                                range.address.split('!')[1]
                                            )
                                            setLoading({
                                                isLoading: false,
                                                message: '',
                                            })
                                        })
                                    }}
                                />
                                <ReactTooltip
                                    id="select-ticker-range"
                                    place="top"
                                />
                            </div>
                        </div>
                        <label
                            htmlFor="parameterRange"
                            className={styles.rangeLabel}
                        >
                            Parameter Range
                        </label>
                        <div className={styles.customInput}>
                            <input
                                id="parameterRange"
                                type="text"
                                placeholder="Parameter range"
                                {...register2('parameterRange', {
                                    required: true,
                                })}
                            />
                            <div className={styles.rxgridContainer}>
                                <RxGrid
                                    data-for="select-parameter-range"
                                    data-tip="Highlight a range then click me"
                                    className={styles.rxgrid}
                                    onClick={async () => {
                                        await Excel.run(async (context) => {
                                            setLoading({
                                                isLoading: true,
                                                message: '',
                                            })
                                            let range =
                                                context.workbook.getSelectedRange()
                                            range.load('address')

                                            await context.sync()

                                            setValue2(
                                                'parameterRange',
                                                range.address.split('!')[1]
                                            )
                                            setLoading({
                                                isLoading: false,
                                                message: '',
                                            })
                                        })
                                    }}
                                />
                                <ReactTooltip
                                    id="select-parameter-range"
                                    place="top"
                                />
                            </div>
                        </div>
                        <ActionButton type={'submit'} title={'Get Data'} />
                        {buckets.map((bucket, index) => {
                            return (
                                <BucketListItem
                                    bucket={bucket}
                                    onSetBucketName={setBucketName}
                                    onRemoveBucket={removeBucket}
                                    onSetBucketTickerRange={
                                        setBucketTickersRange
                                    }
                                    onSetBucketParameterRange={
                                        setBucketParametersRange
                                    }
                                    onRefreshData={runScreenerData}
                                    index={index}
                                />
                            )
                        })}
                    </form>
                )}

                {currentUser.isSubscribed ? null : (
                    <>
                        <div className="full-version-container">
                            <a
                                href="https://wisesheets.io/payment"
                                target="_blank"
                            >
                                <button id="fullversion">
                                    Get Full Version
                                </button>
                            </a>
                        </div>
                        <div className={styles.info}>
                            This is a trial version of our screener get data
                            feature. To see a small scale usage of this feature,
                            please press the button below.
                        </div>
                        <br />
                        <ActionButton
                            title="See demo"
                            type="button"
                            action={runScreenerDemo}
                        />
                    </>
                )}
            </div>
            {isSelectFiltersModalOpen ? (
                <div
                    className={styles.overlay}
                    id="overlay"
                    onClick={(e) => {
                        if (e.target == document.getElementById('overlay')) {
                            setIsSelectFiltersModalOpen(false)
                        }
                    }}
                >
                    <div className={styles.popupContainer}>
                        <div className={styles.popupContent}>
                            <div className={styles.modalHeader}>
                                Choose filters to screen stocks
                            </div>
                            {renderFormOptions()}
                        </div>
                        <div className={styles.closeButtonContainer}>
                            <div
                                onClick={() =>
                                    setIsSelectFiltersModalOpen(false)
                                }
                                className={styles.closeButton}
                            >
                                Close
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {currentMultiSelectFilter ? (
                <div
                    className={styles.overlay}
                    id="overlay"
                    onClick={(e) => {
                        if (e.target == document.getElementById('overlay')) {
                            handleMultiSelectOptionsModalClose()
                        }
                    }}
                >
                    <div className={styles.popupContainer}>
                        <div className={styles.popupContent}>
                            <div className={styles.modalHeader}>
                                Choose filter options:
                            </div>
                            {shouldShowSearch ? (
                                <input
                                    type="text"
                                    placeholder="Search here"
                                    onChange={handleChange}
                                    value={searchInput}
                                    className={styles.searchInput}
                                />
                            ) : null}
                            <div className={styles.popupData}>
                                {selectedMiltiValueFilterData.map(
                                    (obj, index) => {
                                        if (typeof obj === 'object') {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.filterOption
                                                    }
                                                >
                                                    <input
                                                        checked={shouldMultiFilterOptionBeChecked(
                                                            obj.short
                                                        )}
                                                        value={obj.short}
                                                        type="checkbox"
                                                        onChange={
                                                            handleMultiSelectValueCheckboxChange
                                                        }
                                                        id={`${obj.name}checkbox`}
                                                    />
                                                    <label
                                                        className={
                                                            styles.checkBoxLabel
                                                        }
                                                        htmlFor={`${obj.name}checkbox`}
                                                    >
                                                        {obj.name}
                                                    </label>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.filterOption
                                                    }
                                                >
                                                    <input
                                                        checked={shouldMultiFilterOptionBeChecked(
                                                            obj
                                                        )}
                                                        value={obj}
                                                        type="checkbox"
                                                        onChange={
                                                            handleMultiSelectValueCheckboxChange
                                                        }
                                                        id={`${obj}checkbox`}
                                                    />
                                                    <label
                                                        className={
                                                            styles.checkBoxLabel
                                                        }
                                                        htmlFor={`${obj}checkbox`}
                                                    >
                                                        {obj}
                                                    </label>
                                                </div>
                                            )
                                        }
                                    }
                                )}
                            </div>
                        </div>
                        <div className={styles.closeButtonContainer}>
                            <div
                                onClick={handleMultiSelectOptionsModalClose}
                                className={styles.closeButton}
                            >
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className={styles.linkFooter}>
                <a
                    className={styles.link}
                    target="_blank"
                    href="https://wisesheets.io/pages/docs#custom_screener"
                >
                    Wisesheets docs
                </a>
            </div>
        </div>
    )
}

export { Screener }
