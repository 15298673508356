import React, { useState } from 'react'
import mixpanel from 'mixpanel-browser'
import '../../utils/styles/styles.css'
import copy from 'copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { useAuthStore, useErrorStore, useLoadingStore } from '../../state/store'
import ActionButton from '../../components/ActionButton/ActionButton'

export default function WiseFundsFunction() {
    const { currentUser } = useAuthStore((state) => state)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const [tooltipText, setTooltipText] = useState('Click to copy')

    const generateTutorial = async () => {
        setLoading({ isLoading: true, message: 'Generating tutorial...' })
        mixpanel.track('WISEFUNDS_TUTORIAL_GENERATED', {
            distinct_id: currentUser.email,
            isSubscribed: currentUser.isSubscribed,
            subscriptionType: currentUser.subscriptionType,
        })
        try {
            await Excel.run(async (context) => {
                // create the new sheet
                var worksheets = context.workbook.worksheets
                var newSheet = worksheets.add('WISEFUNDS Function Tutorial')

                newSheet.activate()

                var headingsValues = [
                    ['Company', 'NAV', 'Expense Ratio', 'AUM'],
                ]
                var HeadingsRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(
                        headingsValues.length - 1,
                        headingsValues[0].length - 1
                    )
                HeadingsRange.values = headingsValues
                HeadingsRange.format.font.color = '#F7F8F8'
                HeadingsRange.format.fill.color = '#364165'

                var tableBodyValues = [
                    ['SPY', '=WISEFUNDS(A2,$B$1:$D$1)', '', ''],
                    ['IYE', '', '', ''],
                    ['PICK', '', '', ''],
                ]

                var BodyRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A2')
                    .getResizedRange(
                        tableBodyValues.length - 1,
                        tableBodyValues[0].length - 1
                    )
                BodyRange.values = tableBodyValues
                BodyRange.format.columnWidth = 175

                var notes = [
                    ['Cell B2 is pre-populated with our WISE function.'],
                    [
                        'In this function we use multiple parameters to get the data for each ETF at once.',
                    ],
                    [
                        'Cells B1 to F1 are locked so you can drag the function down from B2 to B4 to get the data for the other ETFs',
                    ],
                ]
                var notesRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A6')
                    .getResizedRange(notes.length - 1, notes[0].length - 1)
                notesRange.values = notes
                notesRange.format.font.bold = true

                await context.sync()
            })
        } catch (error) {
            setError({
                hasError: true,
                errorMessage:
                    "Something went wrong. Please make sure the sheet doesn't exist already.",
            })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    return (
        <div>
            <div>
                <span className="wise-green">Symbol(s)</span>
                <div className="param-example">mandatory ex. "SPY"</div>
            </div>
            <div>
                <span className="wise-green">Parameter(s)</span>
                <div className="param-example">
                    mandatory ex. "Expense Ratio", A1:A10
                </div>
            </div>
            <br />
            <br />
            <div
                className="example"
                onClick={() => {
                    copy('=WISEFUNDS("SPY","Expense Ratio")', {
                        format: 'text/plain',
                    })
                    setTooltipText('Copied!')
                }}
                data-tip=""
            >
                =WISEFUNDS("<span>SPY</span>","<span>Expense Ratio</span>")
                <ReactTooltip
                    effect="solid"
                    place="top"
                    afterHide={() => setTooltipText('Click to copy')}
                    getContent={() => tooltipText}
                />
            </div>
            <div
                className="example"
                onClick={() => {
                    copy('=WISEFUNDS("PICK","Nav")', {
                        format: 'text/plain',
                    })
                    setTooltipText('Copied!')
                }}
                data-tip=""
            >
                =WISEFUNDS("<span>PICK</span>","<span>Nav</span>")
                <ReactTooltip
                    effect="solid"
                    place="top"
                    afterHide={() => setTooltipText('Click to copy')}
                    getContent={() => tooltipText}
                />
            </div>
            <div className="tutorial-button-container">
                <ActionButton
                    title={'Click here for a tutorial'}
                    action={generateTutorial}
                    type={'button'}
                    color={'#1FB723'}
                />
            </div>
            {currentUser.isSubscribed ? null : (
                <>
                    <div className="full-version-statement">
                        Trial Mode: Only SPY, IYE, PICK are available
                    </div>
                    <div className="full-version-container">
                        <a href="https://wisesheets.io/payment" target="_blank">
                            <button id="fullversion">Get Full Version</button>
                        </a>
                    </div>
                </>
            )}
            <div className="link-footer">
                <div className="click-here">
                    <a
                        className="link"
                        target="_blank"
                        href="https://wisesheets.io/available-data"
                    >
                        Available data
                    </a>
                </div>
                <div className="click-here">
                    <a
                        className="link"
                        target="_blank"
                        href="https://wisesheets.io/pages/docs"
                    >
                        Wisesheets docs
                    </a>
                </div>
            </div>
            <div style={{ fontSize: 12 }}>
                Function is in beta - ETFs and funds are still being added
            </div>
        </div>
    )
}
