import React, { useEffect, useRef, useState } from 'react'
import '../../utils/styles/styles.css'
import styles from './Functions.module.css'
import WiseFunction from '../WiseFunction/WiseFunction'
import WiseFundsFunction from '../WiseFundsFunction/WiseFundsFunction'
import WisePriceFunction from '../WisePriceFunction/WisePriceFunction'

export default function Functions() {
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)
    const [tabStatment, SetTabStatement] = useState(
        'Best used for obtaining financials, key metrics, revenue segments etc'
    )

    const tabsRef = useRef([])

    const tabs = [
        { label: 'WISE' },
        { label: 'WISEPRICE' },
        { label: 'WISEFUNDS' },
    ]

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex]
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0)
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
        }

        setTabPosition()
        window.addEventListener('resize', setTabPosition)
        if (activeTabIndex === 0)
            SetTabStatement(
                'Best used for obtaining financials, key metrics, revenue segments etc'
            )
        if (activeTabIndex === 1)
            SetTabStatement(
                'Best used for live price data, historical price data, dividend history, etc'
            )
        if (activeTabIndex === 2)
            SetTabStatement(
                'Best used for obtaining ETF/fund data like expense ratio, NAV, AUM etc'
            )

        return () => window.removeEventListener('resize', setTabPosition)
    }, [activeTabIndex])

    const getScreenToDisplay = () => {
        if (activeTabIndex === 0) {
            return <WiseFunction />
        }
        if (activeTabIndex === 1) {
            return <WisePriceFunction />
        }
        if (activeTabIndex === 2) {
            return <WiseFundsFunction />
        }
        return null
    }

    return (
        <div>
            <div className="page-description">{tabStatment}</div>
            <div className={styles.tabsContainer}>
                <div className={styles.tabs}>
                    {tabs.map((tab, index) => {
                        return (
                            <div
                                className={styles.tab}
                                key={index}
                                ref={(el) => (tabsRef.current[index] = el)}
                                onClick={() => setActiveTabIndex(index)}
                            >
                                {tab.label}
                            </div>
                        )
                    })}
                    <span
                        className={styles.tabHighlight}
                        style={{
                            left: tabUnderlineLeft,
                            width: tabUnderlineWidth,
                        }}
                    />
                </div>
            </div>
            {getScreenToDisplay()}
        </div>
    )
}
