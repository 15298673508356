const FINANCIAL_STATEMENTS_AND_KEY_METRICS: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'financial_statements_and_key_metrics',
    parameters_can_be_selected_with: [
        'growth_metrics',
        'dividend_per_share',
        'analyst_estimates',
    ],
    date_selection_whitelist: ['LY', 'LQ', 'TTM', 'SET_YEAR', 'SET_QUARTER'],
    function_name: 'WISE',
    parameters: [
        'Accepted Date',
        'Account Payables',
        'Accounts Payables',
        'Accounts Receivables',
        'Accumulated Other Comprehensive Income Loss',
        'Acquisitions Net',
        'Average Inventory',
        'Average Payables',
        'Average Receivables',
        'Book Value Per Share',
        'Calendar Year',
        'Capex Per Share',
        'Capex To Depreciation',
        'Capex To Operating Cash Flow',
        'Capex To Revenue',
        'Capital Expenditure',
        'Capital Lease Obligations',
        'Cash And Cash Equivalents',
        'Cash And Short Term Investments',
        'Cash At Beginning Of Period',
        'Cash At End Of Period',
        'Cash Per Share',
        'Change In Working Capital',
        'Cik',
        'Common Stock Issued',
        'Common Stock Repurchased',
        'Common Stock',
        'Cost And Expenses',
        'Cost Of Revenue',
        'Current Ratio',
        'Date',
        'Days Of Inventory On Hand',
        'Days Payables Outstanding',
        'Days Sales Outstanding',
        'Debt Repayment',
        'Debt To Assets',
        'Debt To Equity',
        'Deferred Income Tax',
        'Deferred Revenue Non Current',
        'Deferred Revenue',
        'Deferred Tax Liabilities Non Current',
        'Depreciation And Amortization',
        'Depreciation and Amortization Cashflow',
        'Dividend Yield',
        'Dividends Paid',
        'EBITDA Ratio',
        'EBITDA',
        'EPS - historical',
        'EPS Diluted',
        'EV To Free Cash Flow',
        'EV To Operating Cash Flow',
        'EV To Sales',
        'Earnings Yield',
        'Effect Of Forex Changes On Cash',
        'Enterprise Value Over EBITDA',
        'Enterprise Value',
        'Filling Date',
        'Free Cash Flow Per Share',
        'Free Cash Flow Yield',
        'Free Cash Flow',
        'General And Administrative Expenses',
        'Goodwill And Intangible Assets',
        'Goodwill',
        'Graham Net Net',
        'Graham Number',
        'Gross Profit Ratio',
        'Gross Profit',
        'Income Before Tax Ratio',
        'Income Before Tax',
        'Income Quality',
        'Income Tax Expense',
        'Intangible Assets',
        'Intangibles To Total Assets',
        'Interest Coverage',
        'Interest Debt Per Share',
        'Interest Expense',
        'Interest Income',
        'Inventory Cashflow',
        'Inventory Turnover',
        'Inventory',
        'Investments In Property Plant And Equipment',
        'Long Term Debt',
        'Long Term Investments',
        'Market Cap - historical',
        'Minority Interest Preferred Stock',
        'Minority Interest',
        'Net Cash Provided By Operating Activities',
        'Net Cash Used For Investing Activites',
        'Net Cash Used Provided By Financing Activities',
        'Net Change In Cash',
        'Net Current Asset Value',
        'Net Debt To EBITDA',
        'Net Debt',
        'Net Income Cashflow',
        'Net Income Per Share',
        'Net Income Ratio',
        'Net Income',
        'Net Receivables',
        'Operating Cash Flow Per Share',
        'Operating Cash Flow',
        'Operating Expenses',
        'Operating Income Ratio',
        'Operating Income',
        'Other Assets',
        'Other Current Assets',
        'Other Current Liabilities',
        'Other Expenses',
        'Other Financing Activites',
        'Other Investing Activites',
        'Other Liabilities',
        'Other Non Cash Items',
        'Other Non Current Assets',
        'Other Non Current Liabilities',
        'Other Total Stockholders Equity',
        'Other Working Capital',
        'PB Ratio',
        'PE Ratio',
        'PFCF Ratio',
        'POCF Ratio',
        'PTB Ratio',
        'Payables Turnover',
        'Payout Ratio',
        'Period',
        'Preferred Stock',
        'Price To Sales Ratio',
        'Property Plant Equipment Net',
        'Purchases Of Investments',
        'ROE',
        'ROIC',
        'Receivables Turnover',
        'Reported Currency',
        'Research And Ddevelopement To Revenue',
        'Research And Development Expenses',
        'Retained Earnings',
        'Return On Tangible Assets',
        'Revenue Per Share',
        'Revenue',
        'Sales General And Administrative To Revenue',
        'Sales Maturities Of Investments',
        'Selling And Marketing Expenses',
        'Shareholders Equity Per Share',
        'Short Term Debt',
        'Short Term Investments',
        'Stock Based Compensation To Revenue',
        'Stock Based Compensation',
        'Tangible Asset Value',
        'Tangible Book Value Per Share',
        'Tax Assets',
        'Tax Payables',
        'Total Assets',
        'Total Current Assets',
        'Total Current Liabilities',
        'Total Debt',
        'Total Equity',
        'Total Investments',
        'Total Liabilities And Stockholders Equity',
        'Total Liabilities and Total Equity',
        'Total Liabilities',
        'Total Non Current Assets',
        'Total Non Current Liabilities',
        'Total Other Income Expenses Net',
        'Total Stockholders Equity',
        'Weighted Average Shs Out Dil',
        'Weighted Average Shs Out',
        'Working Capital',
    ],
}

const DIVIDEND_HISTORY: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'dividend_history',
    parameters_can_be_selected_with: [],
    date_selection_whitelist: ['ALL_DATES', 'SPECIFIC_DATE'],
    function_name: 'WISEPRICE',
    parameters: ['Dividend - dividend history'],
}

const DIVIDEND_PER_SHARE: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'dividend_per_share',
    parameters_can_be_selected_with: [
        'growth_metrics',
        'financial_statements_and_key_metrics',
        'analyst_estimates',
    ],
    date_selection_whitelist: ['SET_YEAR', 'LQ'],
    function_name: 'WISE',
    parameters: [
        'Dividend - dividend per share',
        'Adjusted Dividend',
        'Exdate',
        'Payment Date',
        'Declaration Date',
    ],
}

const GROWTH_METRICS: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'growth_metrics',
    date_selection_whitelist: ['LY', 'LQ', 'SET_YEAR', 'SET_QUARTER'],
    parameters_can_be_selected_with: [
        'dividend_per_share',
        'financial_statements_and_key_metrics',
        'analyst_estimates',
    ],
    function_name: 'WISE',
    parameters: [
        'Revenue Growth',
        'Gross Profit Growth',
        'EBIT growth',
        'Operating Income Growth',
        'Net Income Growth',
        'EPS Growth',
        'EPS Diluted Growth',
        'Weighted Average Shares Growth',
        'Weighted Average Shares Diluted Growth',
        'Dividends Per Share Growth',
        'Operating Cash Flow Growth',
        'Free Cash Flow Growth',
        'Ten Y Revenue Growth Per Share',
        'Five Y Revenue Growth Per Share',
        'Three Y Revenue Growth Per Share',
        'Ten Y Operating CF Growth Per Share',
        'Five Y Operating CF Growth Per Share',
        'Three Y Operating CF Growth Per Share',
        'Ten Y Net Income Growth Per Share',
        'Five Y Net Income Growth Per Share',
        'Three Y Net Income Growth Per Share',
        'Ten Y Shareholders Equity Growth Per Share',
        'Five Y Shareholders Equity Growth Per Share',
        'Three Y Shareholders Equity Growth Per Share',
        'Ten Y Dividend Per Share Growth Per Share',
        'Five Y Dividend Per Share Growth Per Share',
        'Three Y Dividend Per Share Growth Per Share',
        'Receivables Growth',
        'Inventory Growth',
        'Asset Growth',
        'Book Value per Share Growth',
        'Debt Growth',
        'RD Expense Growth',
        'SGA Expenses Growth',
    ],
}

const ANALYST_ESTIMATES: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'analyst_estimates',
    date_selection_whitelist: ['SET_YEAR', 'SET_YEAR_FUTURE'],
    parameters_can_be_selected_with: [
        'dividend_per_share',
        'financial_statements_and_key_metrics',
        'growth_metrics',
    ],
    function_name: 'WISE',
    parameters: [
        'Estimated Revenue Low',
        'Estimated Revenue High',
        'Estimated Revenue AVG',
        'Estimated EBITDA Low',
        'Estimated EBITDA High',
        'Estimated EBITDA AVG',
        'Estimated EBIT Low',
        'Estimated EBIT High',
        'Estimated EBIT AVG',
        'Estimated Net Income Low',
        'Estimated Net Income High',
        'Estimated Net Income AVG',
        'Estimated SGA Expense Low',
        'Estimated SGA Expense High',
        'Estimated SGA Expense AVG',
        'Estimated EPS AVG',
        'Estimated EPS High',
        'Estimated EPS Low',
        'Number Analyst Estimated Revenue',
        'Number Analysts Estimated EPS',
    ],
}

const LIVE_PRICE_DATA: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'live_price_data',
    parameters_can_be_selected_with: ['company_profile', 'price_change'],
    date_selection_whitelist: ['NO_DATE'],
    function_name: 'WISEPRICE',
    parameters: [
        'Symbol',
        'Name',
        'Price',
        'Changes Percentage',
        'Change - live price',
        'Day Low',
        'Day High',
        'Year High',
        'Year Low',
        'Market Cap - live',
        'Price Avg 50',
        'Price Avg 200',
        'Volume - live',
        'Avg Volume',
        'Exchange',
        'Open - live',
        'Previous Close',
        'EPS - live',
        'PE',
        'Shares Outstanding',
        'Earnings Announcement',
        'Timestamp',
    ],
}

const HISTORICAL_PRICE_DATA: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'historical_price_data',
    parameters_can_be_selected_with: [],
    date_selection_whitelist: ['NUMBER_OF_DAYS', 'SPECIFIC_DATE'],
    function_name: 'WISEPRICE',
    parameters: [
        'Open - historical',
        'High',
        'Low',
        'Close',
        'Adj Close',
        'Volume - historical',
        'Unadjusted Volume',
        'Change - historical',
        'Change Percent',
        'VWAP',
        'Label',
        'Change Over Time',
    ],
}

const PRICE_CHANGE: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'price_change',
    parameters_can_be_selected_with: ['company_profile', 'live_price_data'],
    date_selection_whitelist: ['NO_DATE'],
    function_name: 'WISEPRICE',
    parameters: [
        '1D',
        '5D',
        '1M',
        '3M',
        '6M',
        'Ytd',
        '1Y',
        '3Y',
        '5Y',
        '10Y',
        'Max',
    ],
}

const COMPANY_PROFILE: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'company_profile',
    parameters_can_be_selected_with: ['price_change', 'live_price_data'],
    date_selection_whitelist: ['NO_DATE'],
    function_name: 'WISEPRICE',
    parameters: [
        'Beta',
        'Vol Avg',
        'Last Div',
        'Range',
        'Currency',
        'ISIN',
        'CUSIP',
        'Exchange Short Name',
        'Industry',
        'Website',
        'Description',
        'CEO',
        'Sector',
        'Country',
        'Full Time Employees',
        'Phone',
        'Address',
        'City',
        'State',
        'Zip',
        'Is ETF',
        'Is Actively Trading',
        'Is ADR',
        'Is Fund',
        'IPO Date',
        'Dividend Payments per Year',
    ],
}

const ETF_RELATED_DATA: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'etf_related_data',
    parameters_can_be_selected_with: [],
    date_selection_whitelist: ['NO_DATE'],
    function_name: 'WISEFUNDS',
    parameters: [
        'Asset Class',
        'Aum',
        'Domicile',
        'Etf Company',
        'Expense Ratio',
        'Inception Date',
        'Nav',
        'Nav Currency',
        'Holdings Count',
    ],
}

const GEOGRAPHIC_REVENUES: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'geographic_revenues',
    parameters_can_be_selected_with: [],
    date_selection_whitelist: ['SET_YEAR', 'LQ', 'LY', 'SET_QUARTER'],
    function_name: 'WISE',
    parameters: ['Geographic Revenues'],
}

const SEGMENT_REVENUES: FUNCTION_BUILDER_CATEGORY = {
    bucket_name: 'segment_revenues',
    parameters_can_be_selected_with: [],
    date_selection_whitelist: ['SET_YEAR', 'LQ', 'LY', 'SET_QUARTER'],
    function_name: 'WISE',
    parameters: ['Segment Revenues'],
}

export const EXCHANGES_FILTER =
    'NASDAQ,NYSE,TSX,EURONEXT,MUTUAL_FUND,ETF,AMEX,INDEX,COMMODITY,SHZ,KSC,HKSE,KOSDAQ,XETRA,LSE,IOB,TAI,WSE,JPX,ASX,TWO,OSE,NSE,SAU,STO,SHH,MIL,JKT,HEL,SIX,JNB,MCX,SET,CPH,TLV,IST,SGO,DOH,VIE,EBS,AMS,BRU,ATH,LIS,SAT,PRA,HAM,ICE,SAO,MCE,GER'
export const AUTOSUGGEST_CATEGORY_LIST: FUNCTION_BUILDER_CATEGORY[] = [
    ETF_RELATED_DATA,
    COMPANY_PROFILE,
    PRICE_CHANGE,
    HISTORICAL_PRICE_DATA,
    LIVE_PRICE_DATA,
    ANALYST_ESTIMATES,
    GROWTH_METRICS,
    DIVIDEND_PER_SHARE,
    DIVIDEND_HISTORY,
    FINANCIAL_STATEMENTS_AND_KEY_METRICS,
    GEOGRAPHIC_REVENUES,
    SEGMENT_REVENUES,
]
export const ALL_PARAMETERS = (): PARAMETER_AND_CATEGORY[] =>
    AUTOSUGGEST_CATEGORY_LIST.flatMap((category) =>
        category.parameters.map((parameter) => ({
            parameter,
            category: category.bucket_name,
        }))
    )

export const findParameterCategories = (
    parameter: string
): CATEGORY_NAMES[] => {
    const owningCategories = AUTOSUGGEST_CATEGORY_LIST.filter((category) =>
        category.parameters.includes(parameter)
    )
    return owningCategories.map((category) => category.bucket_name)
}

export const QUARTER_OPTIONS: PERIOD_OPTION[] = [
    {
        id: 1,
        name: 'Q1',
    },
    {
        id: 2,
        name: 'Q2',
    },
    {
        id: 3,
        name: 'Q3',
    },
    {
        id: 4,
        name: 'Q4',
    },
]

export const PERIOD_OPTIONS: PERIOD_OPTION[] = [
    {
        id: 3,
        name: 'LY-N',
        type: 'LY',
        description: 'N years ago, n = 0 to 18',
    },
    {
        id: 5,
        name: 'LQ-N',
        type: 'LQ',
        description: 'N quarters ago, n = 0 to 71',
    },
    {
        id: 2,
        name: 'LY',
        type: 'LY',
        description: 'Latest fiscal year',
    },
    {
        id: 4,
        name: 'LQ',
        type: 'LQ',
        description: 'Latest fiscal quarter',
    },

    {
        id: 1,
        name: 'TTM',
        type: 'TTM',
        description: 'Trailing twelve months',
    },
    { id: 6, name: '2027', type: 'SET_YEAR_FUTURE' },
    { id: 7, name: '2026', type: 'SET_YEAR_FUTURE' },
    { id: 8, name: '2025', type: 'SET_YEAR_FUTURE' },
    { id: 9, name: '2024', type: 'SET_YEAR' },
    { id: 10, name: '2023', type: 'SET_YEAR' },
    { id: 11, name: '2022', type: 'SET_YEAR' },
    { id: 12, name: '2021', type: 'SET_YEAR' },
    { id: 13, name: '2020', type: 'SET_YEAR' },
    { id: 14, name: '2019', type: 'SET_YEAR' },
    { id: 15, name: '2018', type: 'SET_YEAR' },
    { id: 16, name: '2017', type: 'SET_YEAR' },
    { id: 17, name: '2016', type: 'SET_YEAR' },
    { id: 18, name: '2015', type: 'SET_YEAR' },
    { id: 19, name: '2014', type: 'SET_YEAR' },
    { id: 20, name: '2013', type: 'SET_YEAR' },
    { id: 21, name: '2012', type: 'SET_YEAR' },
    { id: 22, name: '2011', type: 'SET_YEAR' },
    { id: 23, name: '2010', type: 'SET_YEAR' },
    { id: 24, name: '2009', type: 'SET_YEAR' },
    { id: 25, name: '2008', type: 'SET_YEAR' },
    { id: 26, name: '2007', type: 'SET_YEAR' },
    { id: 27, name: '2006', type: 'SET_YEAR' },
    { id: 28, name: '2005', type: 'SET_YEAR' },
    { id: 29, name: '2004', type: 'SET_YEAR' },
]

export const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Adding 1 to month as it's zero-based, and padding with leading zero if necessary
    const day = String(date.getDate()).padStart(2, '0') // Padding with leading zero if necessary
    return `${year}-${month}-${day}`
}

export const SEMICOLON_COUNTRY_CODES = [
    'de-DE', // Germany
    'fr-FR', // France
    'es-ES', // Spain
    'it-IT', // Italy
    'nl-BE', // Belgium (Dutch)
    'fr-BE', // Belgium (French)
    'nl-NL', // Netherlands
    'sv-SE', // Sweden
    'da-DK', // Denmark
    'fi-FI', // Finland
    'nb-NO', // Norway
    'pt-PT', // Portugal
    'pl-PL', // Poland
    'de-AT', // Austria
    'de-CH', // Switzerland (German)
    'fr-CH', // Switzerland (French)
    'cs-CZ', // Czech Republic
    'sk-SK', // Slovakia
    'hu-HU', // Hungary
    'el-GR', // Greece
    'ro-RO', // Romania
    'bg-BG', // Bulgaria
    'sl-SI', // Slovenia
    'hr-HR', // Croatia
    'lt-LT', // Lithuania
    'lv-LV', // Latvia
    'et-EE', // Estonia
]
