import React from 'react'
import styles from './ErrorModal.module.css'
import { IoMdAlert } from 'react-icons/io'

type Props = {
    message: string
    onDismissClick: () => void
    isShown: boolean
}

export default function ErrorModal(props: Props) {
    if (props.isShown) {
        return (
            <div className={styles.errorContainer}>
                <div className={styles.error}>
                    <IoMdAlert color="#f65656" size={36} />
                    <div
                        className={styles.errorContent}
                        dangerouslySetInnerHTML={{ __html: props.message }}
                    />
                    <div
                        className={styles.close}
                        onClick={props.onDismissClick}
                    >
                        Dismiss
                    </div>
                </div>
            </div>
        )
    } else return null
}
