import React, { useEffect, useRef, useState } from 'react'
import '../../utils/styles/styles.css'
import './FunctionBuilder.module.css'
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest'
import Autosuggest from 'react-autosuggest'
import axios from 'axios'
import { API_URL_V3, STATEMENT_DUMP_API_KEY } from '../StatementDump/constants'
import { EXCHANGES } from '../Screener/constants'
import { RiCloseLine } from 'react-icons/ri'
import theme from './autocomplete.module.css'
import styles from './FunctionBuilder.module.css'
import Select from 'react-dropdown-select'
import Fuse from 'fuse.js'
import {
    ALL_PARAMETERS,
    AUTOSUGGEST_CATEGORY_LIST,
    EXCHANGES_FILTER,
    formatDate,
    QUARTER_OPTIONS,
    SEMICOLON_COUNTRY_CODES,
} from './constants'
import { PeriodModal } from './Modal/PeriodModal'
import ReactTooltip from 'react-tooltip'
import copy from 'copy-to-clipboard'
import { RxGrid } from 'react-icons/rx'
import { useAuthStore, useLoadingStore } from '../../state/store'
import { IoCopyOutline } from 'react-icons/io5'

const fuseOptions = {
    keys: ['parameter'],
}

const fuse = new Fuse(ALL_PARAMETERS(), fuseOptions)

const FunctionBuilder = () => {
    const [suggestedTickers, setSuggestedTickers] = useState<
        TickerSuggestion[]
    >([])
    const [suggestedParameters, setSuggestedParameters] = useState<
        PARAMETER_AND_CATEGORY[]
    >([])

    const { setLoading } = useLoadingStore((state) => state)
    const [tickerQuery, setTickerQuery] = useState<string>('')
    const [parameterQuery, setParameterQuery] = useState<string>('')
    const [selectedTickers, setSelectedTickers] = useState<TickerSuggestion[]>(
        []
    )
    const [selectedParameters, setSelectedParameters] = useState<
        PARAMETER_AND_CATEGORY[]
    >([])

    const [symbolInputMechanic, setSymbolInputMechanic] = useState<
        'search' | 'select_range'
    >('search')
    useEffect(() => {
        setSelectedTickers([
            {
                symbol: 'AAPL',
                name: 'Apple Inc',
                exchangeShortName: 'NASDAQ',
                stockExchange: 'NASDAQ',
                currency: 'USD',
            },
        ])
        const defaultParameters: PARAMETER_AND_CATEGORY[] = [
            {
                parameter: 'Revenue',
                category: 'financial_statements_and_key_metrics',
            },
        ]
        setSelectedParameters(defaultParameters)
        setSelectedPeriods([{ id: 12, name: '2021', type: 'SET_YEAR' }])
        setAllowedDateSelections(
            findCommonItemsOfDateWhitelistsForActiveCategories(
                defaultParameters
            )
        )
    }, [])

    const [startDate, setStartDate] = useState<string>(formatDate(new Date()))
    const [endDate, setEndDate] = useState<string>(formatDate(new Date()))
    const [isAllDates, setIsAllDates] = useState(false)
    const [isNumberOfDays, setIsNumberOfDays] = useState(false)
    const [numberOfDays, setNumberOfDays] = useState(null)

    const [selectedQuarter, setSelectedQuarter] = useState<PERIOD_OPTION[]>([])
    const [selectedPeriods, setSelectedPeriods] = useState<PERIOD_OPTION[]>([])
    const [allowedDateSelections, setAllowedDateSelections] = useState<
        DATE_SELECTIONS[]
    >([])
    const [shouldAddDateColumn, setShouldAddDateColumn] = useState(true)
    const [tooltipText, setTooltipText] = useState('Click to copy')

    const [shouldShowPeriodModal, setShouldShowPeriodModal] = useState(false)
    const paramSuggestionsInput = useRef(null)

    const renderTickerSuggestion = (ticker: TickerSuggestion) => {
        return (
            <div>
                {ticker.name} - {ticker.symbol}
            </div>
        )
    }
    const onTickerChange = (event: any, { newValue }: any) => {
        setTickerQuery(newValue)
    }

    const isStringAFunctionRange = (str: string): boolean => {
        const regex = /[A-Za-z]+[0-9]+:[A-Za-z]+[0-9]+/
        // determine if a string has a semicolon in it
        return regex.test(str)
    }
    const getSuggestedTickers = async (value: { value: string }) => {
        try {
            const ticker = value.value.toUpperCase()
            const response: { data: TickerSuggestion[] } = await axios.get(
                API_URL_V3 +
                    'search?query=' +
                    ticker +
                    `&limit=5&exchange=${EXCHANGES_FILTER}&apikey=` +
                    STATEMENT_DUMP_API_KEY
            )

            const EXCHANGE_PRIORITY_LIST = EXCHANGES.map((exchange) =>
                exchange.short.toUpperCase()
            )

            const sortedByExchangePriority = response.data.sort((a, b) => {
                return (
                    EXCHANGE_PRIORITY_LIST.indexOf(
                        a.exchangeShortName.toUpperCase()
                    ) -
                    EXCHANGE_PRIORITY_LIST.indexOf(
                        b.exchangeShortName.toUpperCase()
                    )
                )
            })

            setSuggestedTickers(sortedByExchangePriority)
        } catch (e) {}
    }

    const getSuggestedParameters = (paramQuery: string) => {
        const fuseResult = fuse.search(paramQuery)
        const filteredParameters =
            paramQuery.length !== 0
                ? fuseResult.map((result) => result.item)
                : ALL_PARAMETERS()

        if (selectedParameters.length > 0) {
            const categoriesSelectedParamsBelongTo: CATEGORY_NAMES[] =
                selectedParameters.map((param) => param.category)

            const allowedCategoryObjects = AUTOSUGGEST_CATEGORY_LIST.filter(
                (category_obj) =>
                    categoriesSelectedParamsBelongTo.some(
                        (category_name) =>
                            category_obj.parameters_can_be_selected_with.includes(
                                category_name
                            ) || category_obj.bucket_name === category_name
                    )
            )
            const allowedParameters: PARAMETER_AND_CATEGORY[] =
                allowedCategoryObjects.flatMap((category) =>
                    category.parameters.map((parameter) => ({
                        parameter,
                        category: category.bucket_name,
                    }))
                )
            const filteredParametersExcludingCurrentlySelected =
                filteredParameters.filter((param) => {
                    return !selectedParameters.some(
                        (selectedParam) =>
                            selectedParam.parameter === param.parameter
                    )
                })
            const results = filteredParametersExcludingCurrentlySelected.map(
                (param) => {
                    const isAllowed = allowedParameters.some(
                        (filteredParam) =>
                            filteredParam.parameter === param.parameter
                    )
                    return { ...param, isAllowed }
                }
            )
            setSuggestedParameters(results)
        } else {
            const everythingAllowed = filteredParameters.map((param) => {
                return { ...param, isAllowed: true }
            })
            setSuggestedParameters(everythingAllowed)
        }
    }

    const symbolInputProps = {
        placeholder: 'Search for tickers',
        value: tickerQuery,
        onChange: onTickerChange,
    }

    const findCommonItemsOfDateWhitelistsForActiveCategories = (
        parameters: PARAMETER_AND_CATEGORY[]
    ): DATE_SELECTIONS[] => {
        if (parameters.length === 0) {
            return []
        }
        const activeCategories = AUTOSUGGEST_CATEGORY_LIST.filter((category) =>
            parameters.some((param) =>
                category.parameters.includes(param.parameter)
            )
        )
        if (activeCategories.length === 1) {
            return activeCategories[0].date_selection_whitelist
        }
        const activeDateWhitelists = activeCategories.map(
            (category) => category.date_selection_whitelist
        )
        return activeDateWhitelists.reduce((acc, list) => {
            return acc.filter((value) => list.includes(value))
        })
    }

    const buildFunctionCall = () => {
        const activeCategories = AUTOSUGGEST_CATEGORY_LIST.filter((category) =>
            selectedParameters.some((param) =>
                category.parameters.includes(param.parameter)
            )
        )
        if (
            activeCategories.length === 0 ||
            selectedTickers.length === 0 ||
            (selectedPeriods.length === 0 &&
                !allowedDateSelections.includes('SPECIFIC_DATE') &&
                !allowedDateSelections.includes('ALL_DATES') &&
                !allowedDateSelections.includes('NO_DATE'))
        ) {
            return ''
        }

        const functionNamesWithDuplicates = activeCategories.map(function (
            category
        ) {
            return category.function_name
        })

        const seen = {}
        const functionNames = []

        for (let i = 0; i < functionNamesWithDuplicates.length; i++) {
            const name = functionNamesWithDuplicates[i]
            if (!seen[name]) {
                functionNames.push(name)
                seen[name] = true
            }
        }

        const functionName =
            functionNames.length === 1 ? functionNames[0] : 'MULTIPLE'

        const symbolField =
            selectedTickers.length > 1
                ? `{${selectedTickers
                      .map((ticker) => `"${ticker.symbol}"`)
                      .join(',')}}`
                : isStringAFunctionRange(selectedTickers[0].symbol)
                ? `${selectedTickers[0].symbol}`
                : `"${selectedTickers[0].symbol}"`
        const parameterField =
            selectedParameters.length > 1
                ? `{${selectedParameters
                      .map(
                          (parameter) =>
                              `"${parameter.parameter.split('-')[0].trim()}"` // remove description for duplicated fields
                      )
                      .join(',')}}`
                : `"${selectedParameters[0].parameter.split('-')[0].trim()}"`

        const dateField =
            startDate &&
            endDate &&
            allowedDateSelections.includes('SPECIFIC_DATE')
                ? `% "${startDate}", "${endDate}"`
                : ''

        const periodField =
            selectedPeriods.length === 1
                ? `% ${
                      ['SET_YEAR', 'SET_YEAR_FUTURE'].includes(
                          selectedPeriods[0].type
                      )
                          ? selectedPeriods[0].name
                          : `"${selectedPeriods[0].name}"`
                  }`
                : selectedPeriods.length === 0
                ? ''
                : `% {${selectedPeriods
                      .map(
                          (period) =>
                              `${
                                  ['SET_YEAR', 'SET_YEAR_FUTURE'].includes(
                                      period.type
                                  )
                                      ? period.name
                                      : `"${period.name}"`
                              }`
                      )
                      .join(',')}}`

        const quarterField = selectedQuarter.length
            ? `% "${selectedQuarter[0].name}"`
            : ''

        const numberOfDaysField =
            allowedDateSelections.includes('SPECIFIC_DATE') && !isAllDates
                ? numberOfDays !== null && !isNaN(numberOfDays)
                    ? `, ${numberOfDays}`
                    : ','
                : ''

        let builtFunction = `=${functionName}(${symbolField}% ${parameterField}${numberOfDaysField}${
            periodField || dateField
        }${quarterField})`

        if (!shouldAddDateColumn) {
            return `=INDEX(${builtFunction.replace('=', '')}, 0, 2)`
        }

        return builtFunction
    }

    const updateAllowedDateSelectionsAndResetForm = (
        newParameters: PARAMETER_AND_CATEGORY[]
    ) => {
        setAllowedDateSelections(
            findCommonItemsOfDateWhitelistsForActiveCategories(newParameters)
        )
        setSelectedPeriods([])
        setSelectedQuarter([])
        setNumberOfDays(null)
        setStartDate(formatDate(new Date()))
        setEndDate(formatDate(new Date()))
        setIsAllDates(false)
        setIsNumberOfDays(false)
        setShouldAddDateColumn(true)
    }
    const functionCall = buildFunctionCall()
    const { currentUser } = useAuthStore((state) => state)
    return (
        <div>
            {!currentUser.isSubscribed ? (
                <div className={styles.unSubscribedBanner}>
                    You're on the free trial, you can only access data from
                    AAPL, TSLA, and AMZN
                </div>
            ) : (
                <br />
            )}
            {/************************** SYMBOL INPUT *************************/}
            <div className={styles.filterContainer}>
                <div
                    style={{
                        marginBottom: 24,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span className={styles.filterTitle}>Symbol/s</span>
                    <select
                        className={styles.arithmeticFilter}
                        value={symbolInputMechanic}
                        onChange={(event) => {
                            setSelectedTickers([])
                            const value = event.target.value as
                                | 'search'
                                | 'select_range'
                            setSymbolInputMechanic(value)
                        }}
                    >
                        <option value={'search'}>Search</option>
                        <option value={'select_range'}>Range</option>
                    </select>
                </div>
                {symbolInputMechanic === 'select_range' ? (
                    <>
                        <div className={styles.rangeInputContainer}>
                            <input
                                className={styles.input}
                                placeholder={'A1:C1'}
                                onChange={(event) => {
                                    if (
                                        isStringAFunctionRange(
                                            event.target.value
                                        )
                                    ) {
                                        setSelectedTickers([
                                            {
                                                symbol: event.target.value.toUpperCase(),
                                                name: event.target.value,
                                                exchangeShortName: 'NASDAQ',
                                                stockExchange: 'NASDAQ',
                                                currency: 'USD',
                                            },
                                        ])
                                    }
                                }}
                            />
                        </div>
                        <RxGrid
                            data-for="select-ticker-range"
                            data-tip="Highlight a range then click me"
                            style={{
                                position: 'absolute',
                                right: 24,
                                top: 36.5,
                                cursor: 'pointer',
                                fontSize: 14,
                                color: '#aaa',
                            }}
                            onClick={async () => {
                                await Excel.run(async (context) => {
                                    setLoading({
                                        isLoading: true,
                                        message: '',
                                    })
                                    let range =
                                        context.workbook.getSelectedRange()
                                    range.load('address')

                                    await context.sync()
                                    setSelectedTickers([
                                        {
                                            symbol: range.address.split('!')[1],
                                            name: range.address.split('!')[1],
                                            exchangeShortName: 'NASDAQ',
                                            stockExchange: 'NASDAQ',
                                            currency: 'USD',
                                        },
                                    ])

                                    setLoading({
                                        isLoading: false,
                                        message: '',
                                    })
                                })
                            }}
                        />
                        <ReactTooltip id="select-ticker-range" place="top" />
                    </>
                ) : (
                    <Autosuggest
                        theme={theme}
                        suggestions={suggestedTickers}
                        onSuggestionSelected={(
                            event: any,
                            { suggestion }: any
                        ) => {
                            setSelectedTickers([...selectedTickers, suggestion])
                            setTickerQuery('')
                        }}
                        onSuggestionsFetchRequested={getSuggestedTickers}
                        onSuggestionsClearRequested={() =>
                            setSuggestedTickers([])
                        }
                        getSuggestionValue={(symbol: TickerSuggestion) => {
                            return symbol.symbol
                        }}
                        renderSuggestion={renderTickerSuggestion}
                        inputProps={symbolInputProps}
                        focusInputOnSuggestionClick={false}
                    />
                )}

                <div className={styles.selectionContainer}>
                    {selectedTickers.map((ticker) => {
                        return (
                            <div
                                key={ticker.symbol}
                                className={styles.selectedValue}
                            >
                                {ticker.symbol}
                                <RiCloseLine
                                    size={14}
                                    style={{
                                        marginLeft: 2,
                                        cursor: 'pointer',
                                    }}
                                    color="#999999"
                                    onClick={() => {
                                        setSelectedTickers(
                                            selectedTickers.filter(
                                                (selectedTicker) =>
                                                    selectedTicker.symbol !==
                                                    ticker.symbol
                                            )
                                        )
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            {/************************** PARAMETER INPUT *************************/}
            <div className={styles.filterContainer}>
                <span className={styles.filterTitle}>Parameter/s</span>
                <AutoSuggest
                    onInputFocus={() => getSuggestedParameters('')}
                    inputRef={paramSuggestionsInput}
                    onInputBlur={() => {
                        setSuggestedParameters([])
                    }}
                    onInputChange={(e) => {
                        setParameterQuery(e.target.value)
                        getSuggestedParameters(e.target.value)
                    }}
                    placeholder={'Search for parameters'}
                    inputValue={parameterQuery}
                    width={241}
                    currentSuggestions={suggestedParameters}
                    renderSuggestion={(suggestion: PARAMETER_AND_CATEGORY) => (
                        <a
                            title={
                                suggestion.isAllowed
                                    ? null
                                    : "Items from different datasets can't be selected together"
                            }
                        >
                            <div
                                key={suggestion.parameter + suggestion.category}
                                style={{
                                    color: suggestion.isAllowed
                                        ? 'black'
                                        : 'lightgrey',
                                    cursor: suggestion.isAllowed
                                        ? 'pointer'
                                        : 'not-allowed',
                                }}
                                className={styles.autoSuggestionItem}
                                onMouseDown={(e) => {
                                    setSuggestedParameters([])
                                    setParameterQuery('')
                                    if (!suggestion.isAllowed) return
                                    setSelectedParameters((prevState) => {
                                        const newState = [
                                            ...selectedParameters,
                                            suggestion,
                                        ]
                                        updateAllowedDateSelectionsAndResetForm(
                                            newState
                                        )
                                        return newState
                                    })
                                }}
                            >
                                {suggestion.parameter}
                            </div>
                        </a>
                    )}
                />
                <div className={styles.selectionContainer}>
                    {selectedParameters.map((parameter) => {
                        return (
                            <div
                                key={parameter.category + parameter.parameter}
                                className={styles.selectedValue}
                            >
                                {parameter.parameter}
                                <RiCloseLine
                                    size={16}
                                    style={{
                                        marginLeft: 4,
                                        cursor: 'pointer',
                                    }}
                                    color="#999999"
                                    onClick={() => {
                                        setSelectedParameters((prevState) => {
                                            const newState = prevState.filter(
                                                (selectedParameter) =>
                                                    selectedParameter.parameter !==
                                                    parameter.parameter
                                            )
                                            updateAllowedDateSelectionsAndResetForm(
                                                newState
                                            )
                                            return newState
                                        })
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            {/************************** DATE INPUT *************************/}
            {allowedDateSelections.includes('SPECIFIC_DATE') && (
                <div className={styles.filterContainer}>
                    <span className={styles.dateTitle}>Specific Dates</span>
                    <div className={styles.dateInputs}>
                        <input
                            aria-label="Date"
                            type="date"
                            value={startDate}
                            style={{ fontSize: 14, flex: 1 }}
                            onChange={(e) => {
                                setStartDate(e.target.value)
                            }}
                            disabled={isAllDates || numberOfDays !== null}
                        />
                        <input
                            aria-label="Date"
                            type="date"
                            value={endDate}
                            style={{ fontSize: 14, flex: 1 }}
                            onChange={(e) => {
                                setEndDate(e.target.value)
                            }}
                            disabled={isAllDates || numberOfDays !== null}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 8,
                            marginBottom: 8,
                        }}
                    >
                        <div
                            className={styles.allDatesRadio}
                            style={{
                                backgroundColor: shouldAddDateColumn
                                    ? 'green'
                                    : 'white',
                                borderRadius: 5,
                            }}
                            onClick={() => {
                                setShouldAddDateColumn((prevState) => {
                                    return !prevState
                                })
                            }}
                        />
                        Include date column
                    </div>
                    {allowedDateSelections.includes('ALL_DATES') && (
                        <div className={styles.allDatesRadioContainer}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className={styles.allDatesRadio}
                                    style={{
                                        backgroundColor: isAllDates
                                            ? 'green'
                                            : 'white',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => {
                                        setIsAllDates((prevState) => {
                                            const newState = !prevState
                                            if (newState) {
                                                setStartDate(null)
                                                setEndDate(null)
                                            } else {
                                                setStartDate(
                                                    formatDate(new Date())
                                                )
                                                setEndDate(
                                                    formatDate(new Date())
                                                )
                                            }
                                            return newState
                                        })
                                    }}
                                />
                                All dates?
                            </div>
                        </div>
                    )}
                    {allowedDateSelections.includes('NUMBER_OF_DAYS') && (
                        <div className={styles.allDatesRadioContainer}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className={styles.allDatesRadio}
                                    style={{
                                        backgroundColor: isNumberOfDays
                                            ? 'green'
                                            : 'white',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => {
                                        setIsNumberOfDays((prevState) => {
                                            const newState = !prevState
                                            if (newState) {
                                                setStartDate(null)
                                                setEndDate(null)
                                                setNumberOfDays(10)
                                            } else {
                                                setStartDate(
                                                    formatDate(new Date())
                                                )
                                                setEndDate(
                                                    formatDate(new Date())
                                                )
                                                setNumberOfDays(null)
                                            }
                                            return newState
                                        })
                                    }}
                                />
                                Number of days
                            </div>
                        </div>
                    )}
                </div>
            )}
            {allowedDateSelections.includes('NUMBER_OF_DAYS') &&
                numberOfDays !== null && (
                    <div className={styles.filterContainer}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                className={styles.dateTitle}
                                style={{ marginBottom: 0, marginRight: 20 }}
                            >
                                Number of Days:
                            </span>
                            <input
                                type="number"
                                className={styles.input}
                                style={{
                                    width: 60,
                                    textAlign: 'center',
                                    fontSize: 14,
                                }}
                                value={numberOfDays}
                                onChange={(e) =>
                                    setNumberOfDays(parseInt(e.target.value))
                                }
                            />
                        </div>
                    </div>
                )}
            {(allowedDateSelections.includes('SET_YEAR') ||
                allowedDateSelections.includes('SET_YEAR_FUTURE') ||
                allowedDateSelections.includes('LY') ||
                allowedDateSelections.includes('LQ') ||
                allowedDateSelections.includes('TTM')) && (
                <div className={styles.filterContainer}>
                    <div style={{ display: 'flex' }}>
                        <span className={styles.dateTitle}>Period/s</span>
                        <span
                            className={styles.addPeriodButton}
                            onClick={() => setShouldShowPeriodModal(true)}
                        >
                            + Add period/s
                        </span>
                    </div>

                    <div>
                        <div className={styles.selectionContainer}>
                            {selectedPeriods.map((period) => {
                                return (
                                    <div
                                        key={period.id}
                                        className={styles.selectedValue}
                                    >
                                        {period.name}
                                        <RiCloseLine
                                            size={16}
                                            style={{
                                                marginLeft: 4,
                                                cursor: 'pointer',
                                            }}
                                            color="#999999"
                                            onClick={() => {
                                                setSelectedPeriods(
                                                    selectedPeriods.filter(
                                                        (selectedPeriod) =>
                                                            selectedPeriod !==
                                                            period
                                                    )
                                                )
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
            {allowedDateSelections.includes('SET_QUARTER') &&
                selectedPeriods.length !== 0 &&
                // hide quarter selection if TTM, LQ or LQ-N is selected
                !['TTM', 'LQ', 'LY'].some((type: DATE_SELECTIONS) =>
                    selectedPeriods.map((option) => option.type).includes(type)
                ) && (
                    <div
                        className={styles.filterContainer}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <span className={styles.dateTitle}>Quarter</span>
                        <Select
                            multi={false}
                            options={QUARTER_OPTIONS}
                            values={selectedQuarter}
                            closeOnClickInput={true}
                            searchable={false}
                            clearable={true}
                            labelField="name"
                            valueField="id"
                            style={{
                                width: 175,
                                minHeight: 0,
                                borderRadius: 4,
                            }}
                            onChange={(values) => {
                                setSelectedQuarter(values)
                            }}
                        />
                    </div>
                )}
            {functionCall && (
                <>
                    <div
                        style={{
                            height: 2,
                            width: '100%',
                            marginBottom: 12,
                            backgroundColor: '#465484',
                        }}
                    />

                    <div
                        data-tip=""
                        onClick={() => {
                            const myLanguage = Office.context.displayLanguage
                            if (SEMICOLON_COUNTRY_CODES.includes(myLanguage)) {
                                copy(functionCall.replaceAll('%', ';'), {
                                    format: 'text/plain',
                                })
                            } else {
                                copy(functionCall.replaceAll('%', ','), {
                                    format: 'text/plain',
                                })
                            }
                            setTooltipText('Copied!')
                        }}
                        className={styles.generatedFunction}
                    >
                        <ReactTooltip
                            effect="solid"
                            place="top"
                            afterHide={() => setTooltipText('Click to copy')}
                            getContent={() => tooltipText}
                        />
                        {buildFunctionCall().replaceAll('%', ',')}
                        <IoCopyOutline color={'#1fb723'} />
                    </div>
                </>
            )}

            <PeriodModal
                onExternalClick={() => {
                    setShouldShowPeriodModal(false)
                }}
                allowedPeriods={allowedDateSelections}
                selectedCategories={selectedParameters.map(
                    (param) => param.category
                )}
                isShown={shouldShowPeriodModal}
                selectedItems={selectedPeriods}
                onItemSelected={(item: any) => {
                    const isLY = ['LY-N'].includes(item.name)

                    // this field is a hack so we know we're handling LQ or LY
                    if (item.values) {
                        setSelectedPeriods((prevState) => {
                            const existingPeriodRemoved = [
                                ...prevState.filter(
                                    (period) =>
                                        !period.name.startsWith(
                                            isLY ? 'LY-' : 'LQ-'
                                        )
                                ),
                            ]

                            const validStateToAdd: PERIOD_OPTION[] =
                                item.values.map((value) => {
                                    return {
                                        name: isLY
                                            ? `LY-${value}`
                                            : `LQ-${value}`,
                                        type: isLY ? 'LY' : 'LQ',
                                    }
                                })

                            return [
                                ...existingPeriodRemoved,
                                ...validStateToAdd,
                            ]
                        })

                        return
                    }
                    setSelectedPeriods((prevState) => {
                        if (
                            prevState.map((item) => item.id).includes(item.id)
                        ) {
                            return prevState.filter(
                                (period) => period.id !== item.id
                            )
                        } else {
                            return [...prevState, item]
                        }
                    })
                }}
                onSavePressed={() => setShouldShowPeriodModal(false)}
            />
            {!currentUser.isSubscribed ? (
                <div className="full-version-container">
                    <a href="https://wisesheets.io/payment" target="_blank">
                        <button id="fullversion">Get Full Version</button>
                    </a>
                    <br />
                    <br />
                </div>
            ) : null}
            <div
                className="click-here"
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: 20,
                }}
            >
                <a
                    className="link"
                    target="_blank"
                    href="https://wisesheets.io/pages/docs"
                >
                    Wisesheets docs
                </a>
                <a
                    className="link"
                    target="_blank"
                    href="https://wisesheets.io/available-data"
                    style={{ marginLeft: 20 }}
                >
                    Available data
                </a>
            </div>
        </div>
    )
}

export { FunctionBuilder }
