// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DZqnen6_LY8p1x61PU3p {\n    position: relative;\n    margin-bottom: 20px;\n}\n\n.LMZ_hq7ImPg8HV3Cp6Fu {\n    display: flex;\n    background-color: #3e4e7c;\n    justify-content: center;\n    border-radius: 4px;\n}\n\n.GzV6dMYmGEzPgrOL7HBi {\n    padding: 8px;\n    color: #ffffff;\n    width: 50%;\n    text-align: center;\n    cursor: pointer;\n    z-index: 1;\n}\n\n.xsCGdwIs2jaCDM7EZ2Qg {\n    bottom: 0;\n    height: 100%;\n    background-color: #1fb723;\n    transition: all 0.3s ease 0s;\n    position: absolute;\n    display: block;\n    z-index: 0;\n    border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/pages/Functions/Functions.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".tabsContainer {\n    position: relative;\n    margin-bottom: 20px;\n}\n\n.tabs {\n    display: flex;\n    background-color: #3e4e7c;\n    justify-content: center;\n    border-radius: 4px;\n}\n\n.tab {\n    padding: 8px;\n    color: #ffffff;\n    width: 50%;\n    text-align: center;\n    cursor: pointer;\n    z-index: 1;\n}\n\n.tabHighlight {\n    bottom: 0;\n    height: 100%;\n    background-color: #1fb723;\n    transition: all 0.3s ease 0s;\n    position: absolute;\n    display: block;\n    z-index: 0;\n    border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": "DZqnen6_LY8p1x61PU3p",
	"tabs": "LMZ_hq7ImPg8HV3Cp6Fu",
	"tab": "GzV6dMYmGEzPgrOL7HBi",
	"tabHighlight": "xsCGdwIs2jaCDM7EZ2Qg"
};
export default ___CSS_LOADER_EXPORT___;
